@charset "UTF-8";
.conversation-row-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
  white-space: nowrap;
}

.conversation-row-ellipsis.conversation {
  width: 200px;
}

.conversation-row-message-inbox {
  background-color: #fcfdfd;
  border: 1px solid #dbe3e3;
  border-top: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  position: relative;
  cursor: pointer;
}
.conversation-row-message-inbox p {
  margin: 0;
  min-height: 18px;
}

.conversation-row-avatar div {
  width: 32px;
  height: 32px;
}
.conversation-row-avatar div span {
  line-height: 3.1rem;
}

.conversation-row-attachment-icon {
  font-size: 22px;
}

.conversation-row-participants {
  width: 170px;
  line-height: 25px;
}

.conversation-row-empty-icon {
  height: 35px;
  width: 22px;
}

.conversation-row-content {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.conversation-row-content-wrapper {
  width: 90%;
}

.conversation-row-content h4 {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
}

.conversation-row-content .text-info::before {
  content: "•";
  padding-right: 5px;
  font-size: 25px;
  position: relative;
  top: 4px;
}
.conversation-row-content .conversation-row-divider {
  padding: 0 5px;
  line-height: 100%;
}
.conversation-row-content .conversation-row-message {
  color: #707b7b;
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 22px;
  max-width: 200px;
}

.conversation-row-date {
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 25px;
}

.conversation-row-radio-button {
  font-family: "Material Icons";
  font-size: 22px;
  line-height: 14px;
  height: 18px;
}
.conversation-row-radio-button div {
  border: 0.2rem solid #dbe3e3;
}
