.conversation-list-empty-message {
  user-select: none;
  font-size: 60px;
  margin: 0 auto;
  color: rgba(117, 117, 117, 0.2);
  text-shadow: 1px 1px white, -1px -1px rgba(117, 117, 117, 0.2);
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.conversation-list-first {
  border-top: 1px solid #dbe3e3 !important;
}

.conversation-list-month {
  color: #4c5656;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 15px 0px;
}

.conversation-list-loader {
  margin-top: 50px;
}
