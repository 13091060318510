.confirmation-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.confirmation-box .phrase h4,
.confirmation-box .phrase h6 {
  color: #ffffff;
}
.confirmation-box .phrase h4 {
  font-size: 18px;
}
.confirmation-box .phrase h6 {
  font-size: 14px;
}
.confirmation-box .action-buttons {
  display: flex;
  flex-direction: row;
}
.confirmation-box .action-buttons .cancel-button {
  margin-right: 10px;
}
.confirmation-box .action-buttons .confirm-Button {
  margin-left: 10px;
}
