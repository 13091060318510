.users-page-heroTitle {
  text-align: center;
  margin-top: 28px;
  color: white;
  font-weight: 500;
  font-size: 40px;
}

.users-page-heroIcon {
  color: white;
  font-size: 18px;
  text-align: center;
  position: relative;
  bottom: 7px;
}

.users-page-iconCircle {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border: solid white 1px;
  margin-right: 25px;
}

.users-page-tabsButtonsLayer {
  background-color: white;
  width: 100%;
  height: 55px;
  z-index: 1;
  border-bottom: 1px solid #e6e8ec;
}

.users-page-tabsButtonsContainer {
  height: 48px;
  display: flex;
  align-items: center;
  height: 55px;
}
.users-page-tabsButtonsContainer .users-page-leftButtons {
  flex: 1;
}
.users-page-tabsButtonsContainer .users-page-rightButtons {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}
