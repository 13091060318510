.self-reported-ce-detail-page-hero-icon {
  color: white;
  font-size: 25px;
  text-align: center;
  position: relative;
  bottom: 9px;
  line-height: 75px;
}

.self-reported-ce-detail-page-icon-circle {
  display: inline-block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.7);
  border: solid white 1px;
  margin-right: 15px;
}

.self-reported-ce-detail-page-hero-icon-container {
  display: inline-block;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 53px;
  color: white;
  font-weight: 100;
  font-size: 40px;
}

.self-reported-ce-detail-page-hero-title {
  display: inline-block;
  color: white;
  width: 50%;
  position: relative;
  top: 18px;
}

.self-reported-ce-detail-page-hero-title h4 {
  color: white;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.self-reported-ce-detail-page-breadcrumbs {
  margin-top: 16px;
}
.self-reported-ce-detail-page-breadcrumbs a,
.self-reported-ce-detail-page-breadcrumbs span {
  margin-right: 10px;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
}
.self-reported-ce-detail-page-breadcrumbs a {
  opacity: 0.7;
}

.self-reported-ce-detail-page-breadcrumbs-separator {
  vertical-align: middle;
  font-size: 16px;
  margin-right: 10px;
}

.self-reported-ce-detail-page-title {
  display: flex;
  align-items: center;
  padding: 35px 0 30px 10px;
}
.self-reported-ce-detail-page-title h5 {
  display: inline;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  margin-left: 4px;
}
.self-reported-ce-detail-page-title .icense-detail-page-titleIcons {
  margin-right: 15px;
  font-size: 15px;
  color: #90a4ae;
}
.self-reported-ce-detail-page-title .self-reported-ce-detail-periods-select {
  width: 210px;
  margin-left: 15px;
}

.self-reported-ce-detail-page-content {
  margin-top: 20px;
}

.self-reported-ce-detail-page-loader-container {
  height: 200px;
  margin-top: 30px;
}
.self-reported-ce-detail-page-loader-container .self-reported-ce-detail-page-loader {
  height: 200px;
  position: relative;
}

.self-reported-ce-detail-page-card {
  margin-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgba(150, 150, 150, 0.1),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 1px;
  background-color: #ffffff;
  font-size: 14px;
}
.self-reported-ce-detail-page-card .panel-body {
  display: flex;
  padding: 3.2rem 2rem !important;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardHeader {
  padding: 0 5rem 0 0;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody {
  flex: 1;
  color: #212121;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody > div {
  flex: 1;
  display: flex;
  margin-bottom: 25px;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody > div:last-child {
  margin-bottom: 0;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody > div > div {
  flex: 1;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody .self-reported-ce-detail-page-cardTitle {
  color: #757575;
  margin-bottom: 5px;
}
.self-reported-ce-detail-page-card .panel-body .self-reported-ce-detail-page-cardBody .self-reported-ce-detail-page-avatar {
  padding: 0;
  margin-right: 5px;
}
.self-reported-ce-detail-page-card .cardText .cardBody .avatar > div {
  width: 20px;
  height: 20px;
}
.self-reported-ce-detail-page-card .cardText .cardBody .avatar > div > span {
  font-size: 10px;
  line-height: 20px;
}

.self-reported-ce-detail-page-red {
  color: #f44048;
}

.self-reported-ce-detail-page-grey {
  color: #cfd8dc;
}

.self-reported-ce-detail-page-green {
  color: #81c784;
}

.self-reported-ce-detail-page-floatingbox {
  margin-bottom: 10px;
}
.self-reported-ce-detail-page-floatingbox [data-ref="floatingbox-header"]{
  height: 59px !important;
}
@media (min-width: 992px) {
  .self-reported-ce-detail-page-floatingbox {
    margin-top: -59px !important;
    width: 284px;
  }
}
@media (min-width: 1280px) {
  .self-reported-ce-detail-page-floatingbox {
    position: relative;
    margin-top: -59px;
    width: 284px;
  }
}

.self-reported-ce-detail-page-action-buttons {
  margin: auto;
  width: 70%;
}

.self-reported-ce-detail-page-main-action-button {
  box-shadow: 0 0 0;
  color: white !important;
}

.self-reported-ce-detail-page-transcript-loader-card {
  min-height: 190px;
  position: relative;
}

.self-reported-ce-detail-page-blank-state {
  background-color: white;
  height: 150px;
  text-align: center;
}

.self-reported-ce-detail-page-blank-state div {
  margin-top: 50px;
  line-height: 50px;
  user-select: none;
  font-size: 30px;
  color: rgba(117, 117, 117, 0.4);
}

.self-reported-ce-detail-page-apply-to-prior-cycle {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}
.self-reported-ce-detail-page-apply-to-prior-cycle .self-reported-ce-detail-page-applyMessage {
  cursor: auto;
  width: inherit;
  min-height: 24px;
  font-size: 13px;
  background-color: #f44048;
  justify-content: center;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-radius: 0;
}

.self-reported-ce-detail-page-popover {
  height: 400px;
  overflow: auto;
}

.self-reported-ce-detail-page-popover-title small {
  font-size: 12px;
}

.self-reported-ce-detail-page-question {
  margin-bottom: 15px;
}

.self-reported-ce-detail-page-attachment-box {
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 5px;
}
.self-reported-ce-detail-page-attachment-box .self-reported-ce-detail-page-attachmentImg {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.self-reported-ce-detail-page-no-padding .panel-body {
  padding: 0 !important;
}


.self-reported-ce-action-box-actionButtons {
  display: flex;
}
.self-reported-ce-action-box-actionButtons button {
  flex-grow: 1;
  margin-right: 5px;
}
.self-reported-ce-action-box-actionButtons > div {
  flex-grow: 1;
  border-radius: 3px;
  color: #546e7a !important;
}

.self-reported-ce-attachment-box {
  width: 100%;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;
  position: relative;
}

.self-reported-ce-action-box-confirm-box {
  flex-direction: column;
}

.self-reported-ce-action-box-confirm-box .phrase h5{
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.self-reported-ce-action-box-confirm-box .actionButtons {
  position: relative;
  flex-direction: row;
  display: flex;
  margin-top: 10px;
}

.self-reported-ce-action-box-confirm-box .actionButtons .cancelButton {
  margin-right: 20px;
}
