.login-screen-container {
  text-align: center;
  margin-top: 35vh;
}

.login-screen-heading-box {
  position: relative;
}
.login-screen-heading-box .heading {
  color: #555;
}
.login-screen-heading-box .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+29,ffffff+100&0+0,1+28,1+100 */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 */
  animation: scroll 2s ease 0.5s 1;
  animation-fill-mode: forwards;
}

.login-screen-shape-black {
  fill: #eee;
  animation: fill-black 4s ease 0s infinite;
}

.login-screen-shape-green {
  fill: #eee;
  animation: fill-green 4s ease 0.5s infinite;
}

.login-screen-shape-red {
  fill: #eee;
  animation: fill-red 4s ease 1s infinite;
}

.login-screen-logo {
  position: relative;
  left: 80px;
}

@keyframes fill-black {
  0% {
    fill: #eee;
  }
  50% {
    fill: #000;
  }
  100% {
    fill: #eee;
  }
}

@keyframes fill-green {
  0% {
    fill: #eee;
  }
  50% {
    fill: #72a893;
  }
  100% {
    fill: #eee;
  }
}

@keyframes fill-red {
  0% {
    fill: #eee;
  }
  50% {
    fill: #d2232a;
  }
  100% {
    fill: #eee;
  }
}
