.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #0092FF;
  width: 30px;
  height: 30px;
  text-indent: -9999em;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: auto; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
