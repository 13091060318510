.tie-users-page-table {
  margin-top: 50px;
}

.tie-users-page-table  td {
  text-align: center;
}

.tie-users-page-icon {
  width: 10px;
}

.tie-users-page-actions {
  text-align: right !important;
}

.tie-users-page-board-icon {
  width: 20px;
  justify-self: end;
}

.tie-users-page-add-new-record-btn {
  margin-top: -32px;
  float: right;
}

.tie-users-col {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
  align-items: stretch;
}

.tie-users-column {
  padding: 21px 21px !important;
  vertical-align: inherit !important;
}

.tie-users-column-vertical {
  padding: 21px 0px 21px 21px !important;
  vertical-align: inherit !important;
}

.tie-users-row-content {
  text-align: left;
  line-height: 2;
}

.tie-users-field-select {
  width: 100%;
  padding-bottom: 0px !important;
}
