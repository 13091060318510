.activityFeed {
  position: relative;
}
.activityFeed .activities {
  max-height: 500px;
  overflow: auto;
}
.activityFeed .BoxNotification {
  text-align: center;
}
.activityFeed .BoxNotification h5 {
  margin-bottom: 30px;
  color: white;
  font-size: 18px;
}
.activityFeed .blankState {
  padding: 20px 0;
  color: #757575;
}
