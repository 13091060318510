.paginator {
  background: white;
  border: 1px solid #e6e8ec;
  display: flex;
  padding: 17px 24px;
  font-size: 13px;
}

.paginator-left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: baseline;
}
.paginator-left select {
  background: transparent;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}
.paginator-left select:focus {
  outline: none;
}
.paginator-left label {
  font-weight: normal;
}

.paginator-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
}
.paginator-right span[data-react-toolbox] {
  cursor: pointer;
  user-select: none;
}

.paginator-chevrones {
  margin-right: 0 !important;
}
.paginator-chevrones [data-react-toolbox] {
  cursor: pointer;
  font-size: 22px;
  margin-left: 10px;
  margin-right: 0;
}

.paginator-paginationNumber {
  font-weight: 600;
}

.paginator-hidden {
  color: #ddd;
}
