.floater-popover {
    height: 18rem;
    width: 30rem;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2 !important;
}

.floater-popover .eui-popover.right {
    margin-left: 10px;
    width: 100%;
}

.floater-popover .eui-popover.right .arrow {
    display: none;
}

.progress-popover {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.wrapper-button-popover {
    text-align: center;
}

.hidden-button-popover {
    display: none;
}