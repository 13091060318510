.activities-accordion {
  background-color: white;
  padding: 20px;
  text-align: center;
}
.activities-accordion span {
  cursor: pointer;
}

.activities-comment {
  background-color: white;
  display: flex;
  padding: 5px 10px;
  transition: background-color 1s ease;
  transition: all 1s ease;
  cursor: pointer;
}

.activities-comment:last-child {
  padding-bottom: 40px;
}

.activities-comment.activities-editing {
  background-color: #f5f5f5;
}

.activities-comment:hover .activities-edit {
  opacity: 1;
}
.activities-comment .activities-avatar {
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
}
.activities-comment .activity-avatarImg {
  margin-right: 5px;
  padding: 0;
  margin-right: 5px;
}
.activities-comment .activity-avatarImg > div {
  width: 30px;
  height: 30px;
}
.activities-comment .activity-avatarImg > div > span {
  font-size: 20px;
  line-height: 27px;
}
.activities-comment .activities-container p > span {
  margin-right: 5px;
  font-weight: 600;
}
.activities-comment .activities-container em {
  font-style: normal;
}
.activities-comment .activities-edit {
  font-size: 12px;
  margin-left: 10px;
  text-decoration: underline;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.activities-comment .activities-edit:hover {
  text-decoration: none;
}

.activities-wrapper {
  background-color: white;
  padding: 5px 10px;
}
.activities-wrapper .activities-activity {
  display: flex;
  transition: background-color 1s;
  cursor: pointer;
}
.activities-wrapper .activities-activity:hover {
  background-color: #f5f5f5;
}
.activities-wrapper .activities-activity .activities-avatar {
  padding: 5px;
  margin-right: 10px;
  margin-left: 5px;
}
.activities-wrapper .activities-activity .activity-avatarImg {
  margin-right: 5px;
  font-size: 10px;
  padding: 0;
  margin-right: 5px;
}
.activities-wrapper .activities-activity .activity-avatarImg > div {
  width: 20px;
  height: 20px;
}
.activities-wrapper .activities-activity .activity-avatarImg > div > span {
  font-size: 10px;
  line-height: 18px;
}
.activities-wrapper .activities-activity .activities-container {
  display: flex;
  align-items: center;
}
.activities-wrapper .activities-activity .activities-container p {
  font-style: italic;
}
.activities-wrapper .activities-activity .activities-container em {
  font-style: italic;
}
.activities-wrapper .activities-showMessage {
  width: 100%;
  padding: 0 47px;
  text-align: left;
  display: flex;
  font-size: 13px;
}
.activities-wrapper .showMessage i {
  margin-right: 6px;
}

.activities-postForm {
  display: flex;
  padding: 5px 10px;
  background-color: white;
  align-items: baseline;
}
.activities-postForm form {
  display: flex;
  flex: 1;
  align-items: baseline;
}
.activities-postForm form .eui-text-field {
  flex-grow: 3;
  font-size: 15px;
  margin-left: 10px;
  padding-bottom: 0;
}
.activities-postForm form button {
  flex-grow: 1;
  margin-left: 5px;
  border: 1px solid #e0e0e0;
  height: 40px;
}
.activities-postForm form button:hover {
  background-color: #f5f5f5;
}

.activities-postForm .activities-postForm-button{
  height: 100%;
}

.activities-postForm .activities-postForm-button .eui-btn-styles{
  padding: 14px 19px;
}

.activities-postForm .activity-avatarImg {
  margin-right: 5px;
  font-size: 20px;
  padding: 0;
  margin-right: 5px;
  margin-top: 4px;
}
.activities-postForm .activity-avatarImg > div {
  width: 30px;
  height: 30px;
}
.activities-postForm .activity-avatarImg > div > span {
  font-size: 20px;
  line-height: 27px;
}
