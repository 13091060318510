.user-profile-detail-wrapper {
  padding: 38px 38px 44px 72px;
}

.user-profile-detail-header {
  display: flex;
  padding-bottom: 33px;
  border-bottom: 2px solid #f1f5f5;
}
.user-profile-detail-header .user-profile-detail-header-image {
  margin-right: 12px;
}
.user-profile-detail-header .user-profile-detail-header-image img {
  height: 54px;
  width: 54px;
}
.user-profile-detail-header .user-profile-detail-header-info {
  width: 100%;
  color: rgba(91, 91, 91, 0.87);
}
.user-profile-detail-header .user-profile-detail-header-info .user-profile-detail-header-info-title {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
}
.user-profile-detail-header .user-profile-detail-header-info .user-profile-detail-header-info-user-name {
  font-size: 28px;
  font-weight: 300;
  line-height: 38px;
}
.user-profile-detail-header .user-profile-detail-header-info .user-profile-detail-header-info-user-email {
  margin-top: 16px;
  font-size: 13px;
  line-height: 22px;
}
.user-profile-detail-header .user-profile-detail-header-info .user-profile-detail-header-info-user-phone {
  font-size: 13px;
  line-height: 22px;
}

.user-profile-detail-content {
  padding-top: 42px;
  padding-left: 66px;
}
.user-profile-detail-content .user-profile-detail-row {
  display: flex;
  font-size: 13px;
  margin-bottom: 35px;
}
.user-profile-detail-content .user-profile-detail-row .user-profile-detail-left {
  width: 133px;
  font-weight: 600;
  margin-right: 25px;
}
.user-profile-detail-content .user-profile-detail-row .user-profile-detail-left span {
  display: block;
}
.user-profile-detail-content .user-profile-detail-row .user-profile-detail-right {
  width: 100%;
}
.user-profile-detail-content .user-profile-detail-row .user-profile-detail-right.user-profile-detail-status {
  display: flex;
}
.user-profile-detail-content .user-profile-detail-row .user-profile-detail-right.user-profile-detail-status span.status-text {
  margin: 4px 0 0 15px;
}

.user-profile-detail-link {
  color: #59b9f4;
  cursor: pointer;
}
.user-profile-detail-link:hover {
  color: #42a5f5;
}

.user-profile-detail-custom-radio > div {
  width: 12px;
  height: 12px;
  border: 1px solid #666;
}
.user-profile-detail-custom-radio > div:before {
  background-color: #666;
}

.user-profile-detail-buttons {
  display: flex;
  flex-direction: row-reverse;
}
.user-profile-detail-buttons button.updateButton {
  height: 35px;
  width: 164px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  color: #fff;
  border: none;
  text-transform: uppercase;
}

.user-profile-detail-dropdown {
  width: 140px;
  padding-bottom: 0px;
  z-index: 200;
}

.user-profile-detail-dropdown .eui-select-field-wrapper button {
  font-size: 13px;
}

.user-profile-detail-role-section {
  align-items: center;
}

.user-profile-detail-checkbox [data-react-toolbox="check"] {
  border-width: 1px;
}

.user-profile-detail-checkbox [data-react-toolbox="label"] {
  font-size: 13px;
}
