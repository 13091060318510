.notification-panel-item {
  display: flex;
  height: 116px;
  padding: 20px;
  border-bottom: 1px solid #dbe3e3;
  cursor: pointer;
}

.notification-panel-item-iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-panel-item-messageContainer {
  padding-left: 20px;
}
.notification-panel-item-messageContainer .notification-panel-item-title {
  color: #8d9a99;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.notification-panel-item-messageContainer .notification-panel-item-description {
  color: #455a64;
  font-size: 14px;
  padding-top: 10px;
  padding-right: 15px;
  font-weight: 600;
}

.notification-panel-item-oval {
  height: 35px;
  width: 35px;
  border: 1px solid #dbe3e3;
  background-color: rgba(243, 246, 247, 0);
  border-radius: 50%;
  color: #81c784;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-panel-item-oval i {
  font-size: 18px;
}

.notification-panel-item-unread {
  background-color: #f1f5f5;
}
