.box-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 50, 56, 0.85);
  padding: 20px;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  color: white;
  transition: visibility 0s, opacity 1s ease;
}

.box-notification-hidden {
  visibility: hidden;
  opacity: 0;
}
