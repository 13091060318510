.conversations-messages-section {
  margin-top: 30px;
}

.board-nav-header-bg{
  background: #0e457c;
}

.inbox .inbox-tablist .eui-nav>a:first-child{
  margin-left: 0;
}

.inbox .inbox-tablist > div {
  overflow: initial !important;
}
