.search-user-container {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .search-user-container {
    margin-top: 30px;
  }
}

.search-user-avatar-image {
  margin-right: 21px;
}

.search-user-red {
  color: #f44048;
}

.search-user-grey {
  color: #cfd8dc;
}

.search-user-green {
  color: #81c784;
}

.search-user-statusIndicator {
  user-select: none;
  cursor: pointer;
  font-size: 12px !important;
  padding-right: 8px;
}

span.search-user-stateCode {
  font-weight: 600;
}

.search-user-name-column {
  flex: 0 0 200px;
}

.search-user-email-column {
  flex: 1 0 280px;
}

.search-user-user-name-wrapper {
  height: 20px;
  display: flex;
  align-items: center;
}

.search-user-avatar-image div {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.search-user-avatar-image div span {
  line-height: 30px;
}

.search-user-arrow-row {
  user-select: none;
  cursor: pointer;
}
.search-user-arrow-row:hover {
  color: #455a64;
}

.search-user-state-count {
  margin-right: 5px;
}

.search-user-clickable-row {
  cursor: pointer;
}

.search-user-table {
  border-top: none;
  border-bottom: none;
}
