.new-user-modal {
  width: 342px;
}

.new-user-modal-header {
  padding: 18px 0;
  text-align: center;
  display: block;
  border-bottom: 1px solid #dbe3e3;
}

.new-user-modal-link-wrapper {
  padding: 40px 0;
  text-align: center;
}
.new-user-modal-link-wrapper a {
  font-size: 14px;
  text-decoration: none;
}
.new-user-modal-link-wrapper a:hover {
  opacity: 1;
}
.new-user-modal-link-wrapper a [class="material-icons"] {
  font-size: 22px;
  position: absolute;
  margin-left: -26px;
  margin-top: 0px;
}
