.create-user-container {
  margin-top: 50px;
}

.create-user-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 26px;
}

.create-user-form {
  background-color: white;
  border: 1px solid #dbe3e3;
  padding: 38px 100px 70px 45px;
  margin-bottom: 100px;
}

.create-user-left-column {
  font-size: 14px;
}

.create-user-form-section {
  margin-bottom: 50px;
}

.create-user-inputs-section {
  padding-bottom: 0;
  margin-bottom: 10px;
}
.create-user-inputs-section input {
  padding: 3px 0;
  font-size: 13px;
}
.create-user-inputs-section label {
  font-size: 13px;
}

.create-user-roleDropdown {
  margin-top: 40px;
  width: 100%;
}


.create-user-boards-dropdown-group {
  display: flex;
  margin-bottom: 5px;
}
.create-user-boards-dropdown-group .boardsDropdown {
  font-size: 13px;
  flex: 1;
}
.create-user-boards-dropdown-group .close {
  user-select: none;
  padding-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  color: #b4b4b4;
}
.create-user-boards-dropdown-group .close:hover {
  color: #848484;
}

.create-user-board-checkboxes-group {
  padding-left: 10px;
  margin-bottom: 25px;
}
.create-user-board-checkboxes-group label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.create-user-board-checkboxes-group label span {
  font-weight: 400;
}

.create-user-checkbox [data-react-toolbox="check"] {
  border-width: 1px;
}

.create-user-checkbox [data-react-toolbox="label"] {
  font-size: 13px;
}

.create-user-buttonsSection {
  text-align: right;
}
.create-user-buttonsSection .create-user-cancelButton {
  margin-right: 15px;
  padding: 0 10px;
  height: 35px;
  font-weight: bold;
  font-size: 12px;
  border: none;
  background-color: transparent;
  border-radius: 3px;
  text-transform: uppercase;
}
.create-user-buttonsSection .create-user-cancelButton:hover {
  background-color: rgba(0, 146, 255, 0.2);
}
.create-user-buttonsSection .create-user-submitButton {
  width: 131px;
  height: 35px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
}

.create-user-disabled input {
  color: rgba(0, 0, 0, 0.6) !important;
}

.create-user-permissions-label span {
  color: #aaa;
}
