.loadingBox {
	display: flex;
	flex-direction: column;
	background-color: rgba(38, 50, 56, 0.55);
}
.loadingBox .loader {
	display: block;
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #0092ff;
	width: 60px;
	height: 60px;
	text-indent: -9999em;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
.loadingBox .phrase {
	margin: 20px;
}
.loadingBox .phrase h5 {
	font-size: 24px;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
