.floating-box {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
}

.floating-box-header {
  background: #0073e9;
  color: #ffffff;
  height: 38px;
  font-size: 13px;
  display: flex;
  padding-left: 17px;
}
.floating-box-header span {
  align-self: center;
}

.floating-box-footer {
  background-color: #f1f5f5;
}

.floating-box-body {
  background: #ffffff;
  padding: 16px;
}

@media (min-width: 991px) {
  .floating-box-fix-box {
    position: fixed !important;
    top: 195px;
    z-index: 100;
  }
}
