.default {
  [class="Select-control"] {
    background-color: transparent;
  }
  &[class~="is-pseudo-focused"], &[class~="is-focused"] {
    [class="Select-control"] {
      border-color: #ccc;
      box-shadow: none;
    }
  }
}

.borderless {
  min-width: 75px;
  [class="Select-control"] {
    background-color: transparent;
    [class="Select-value"] {
      padding-left: 0;
      padding-right: 0;
    }
    border: none !important;
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0);
    }
  }

  &[class~="is-pseudo-focused"], &[class~="is-focused"] {
    [class="Select-control"] {
      border: none;
      box-shadow: none;
    }
  }

  &[class~="is-disabled"] {
    [class="Select-control"] {
      background-color: transparent !important;
    }
  }
}

.material {}
