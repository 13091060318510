.boards-dropdown-dropdown {
	z-index: 100;
	padding-top: 5px !important;
	width: 300px;
	padding-bottom: 0 !important;
}
.boards-dropdown-dropdown label {
	top: -17px !important;
}
.boards-dropdown-dropdown label.select-label {
	color: white;
	font-size: 10px;
}
.boards-dropdown-dropdown .select-caption {
	color: white;
	font-size: 13px;
}

.boards-dropdown-dropdown .eui-text-field-wrapper > input {
	color: white;
}

.boards-dropdown-dropdown button.eui-select-trigger {
	color: white;
	white-space: nowrap;
	overflow: hidden;
}

.boards-dropdown-dropdown button.eui-select-trigger > div {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.boards-dropdown-dropdown button.eui-select-trigger::after {
	border-top-color: white;
}

.boards-dropdown-dropdown button.eui-select-trigger::before {
	content: 'Boards';
	position: absolute;
	color: white;
	top: -4px;
	opacity: 0.4;
}

.boards-dropdown-dropdown .select-trigger {
	padding: 0 !important;
}
.boards-dropdown-dropdown a span.select-line {
	color: white;
}
.boards-dropdown-dropdown .select-trigger div {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.boards-dropdown-dropdown::after {
	top: 8px !important;
	right: 2px !important;
}

.boards-dropdown-dropdown a.select-trigger:after {
	border-top: 15px solid #ffffff !important;
}

.navbar-nav span[role=button] {
	padding: 19px;
	display: inline-block;
	line-height: 20px;
}

.boards-dropdown-dropdown .menu-wrapper .tooltip-container{
	overflow: hidden;
	height: 0 !important;
}
