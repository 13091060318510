.loader-logo {
	display: inline-block;
	margin: 0px 10px 0px 0px !important;
	margin-right: 10px;
}

.skeleton-loader {
	transition: all 0.3s ease-out;
}

.loader-logo .placeholder {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 2px;
}

.external-logo {
	max-width: 140px;
	margin-right: 10px;
	opacity: 0;
	transition: all .2s ease-out;
}

.external-logo.loaded {
	opacity: 1;
}
