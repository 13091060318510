.board-account-logo {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.board-account-card {
  width: 496px;
  padding: 20px;
}
.board-account-card .board-account-cardMessage {
  text-align: center;
  color: #333;
  margin: 10px 0;
}
.board-account-card .board-account-cardButton {
  max-width: 97px;
  width: 97px;
  border-radius: 3px;
  margin-top: 20px;
}
.board-account-card .board-account-complex-password-errors {
  margin-left: 20px;
  margin-top: 20px;
  color: red;
  font-size: 14px;
  line-height: 24px;
}
.board-account-card .board-account-complex-password-errors p {
  font-weight: 600;
}

.board-account-cardTitle {
  color: #333;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.board-account-footerText {
  margin-top: 15px;
  margin-left: 30px;
}

.board-account-loader {
  width: 100%;
  height: 100%;
}

.board-account-logo-card {
  text-align: center;
  padding: 10px;
}
