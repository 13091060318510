.single-login-page .navbar-default .navbar-nav > li > a {
  color: white !important;
}

.single-login-page-appcards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 260px));
  justify-items: center;
  justify-content: center;
}

.single-login-page-text {
  font-size: 28px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.single-login-page-classic {
  margin-bottom: 24px;
}

.single-login-page-nextgen .app-card-circle img {
  margin-left: 7px;
}

.single-login-page-nextgen p {
  padding-left: 10px;
  padding-right: 10px;
}

.single-login-page-classic .app-card-circle img {
  margin-top: 17px !important;
  margin-left: 6px;
}
