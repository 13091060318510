.redirect-button {
	margin-top: 20px;
	border-radius: 50px;
	background-color: rgba(255, 255, 255, 0.2);
	border: none;
	color: white;
}

.mr-8 {
	margin-right: 9px;
}
