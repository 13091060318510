.conversation-filter-bar-filters {
  display: flex;
}
.conversation-filter-bar-filters .conversation-filter-bar-search-box {
  flex-grow: 1;
  display: flex;
  background-color: #d8e1e6;
  border-radius: 3px;
}
.conversation-filter-bar-filters .conversation-filter-bar-search-box .conversation-filter-bar-search-icon {
  height: 25px;
  margin-top: 8px;
  margin-left: 10px;
}
.conversation-filter-bar-filters .conversation-filter-bar-search-box .conversation-filter-bar-search-input {
  flex-grow: 1;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  color: #4c5656;
}

.conversation-filter-bar-filters-icon {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  color: #8096a3;
  font-weight: 600;
  cursor: pointer;
  padding: 0 10px;
}
.conversation-filter-bar-filters-icon span {
  font-size: 22px;
}
.conversation-filter-bar-filters-icon .conversation-filter-bar-filters-text {
  font-size: 14px;
}

.search-conversation-term {
  border-bottom: 1px solid #e6e8ec !important;
}
