.audit-account-logo {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.audit-account-card {
  width: 496px;
}
.audit-account-textfields {
  padding: 20px;
}
.audit-account-card .audit-account-audit-account-cardMessage {
  text-align: center;
  color: #333;
  margin: 10px 0;
  white-space: nowrap;
  padding: 20px;
}
.audit-account-card .audit-account-audit-account-cardButton {
  max-width: 97px;
  width: 97px;
  border-radius: 3px;
  margin-top: 20px;
}
.audit-account-card .audit-account-complex-password-errors {
  margin: 20px;
  color: red;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 20px;
}
.audit-account-card .audit-account-complex-password-errors p {
  font-weight: 600;
}

.audit-account-cardTitle {
  color: #333;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.audit-account-footerText {
  margin-top: 15px;
  margin-left: 30px;
}

.audit-account-loader {
  width: 100%;
  height: 100%;
}
