.board-user-profile-header-title {
  margin-top: 40px;
}

.board-user-profile-board-user-name {
  margin-bottom: 30px;
  font-weight: bold !important;
  font-size: 30px;
}

.board-user-profile-profile-header {
  background-color: #0092ff;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 268px;
  padding-bottom: 55px;
}

.board-user-profile-container {
  margin: 20px 0;

}

.board-user-profile-section-form {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #f1f5f5;
  padding-top: 20px;
}
.board-user-profile-section-form .section-despcription {
  margin-left: 12px;
}
.board-user-profile-section-form .section-despcription h6 {
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
}
.board-user-profile-section-form .section-despcription p {
  font-size: 12px;
  line-height: 16px;
  width: 140px;
}
.board-user-profile-section-form .section-inputs {
  margin-right: 95px;
  width: 388px;
}

.board-user-profile-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16.5px;
}
.board-user-profile-input-group label {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 3px;
}

.board-user-profile-input-group input {
  font-size: 12px;
  border: none;
  border-bottom: 1px solid #e9e9e9;
}

.board-user-profile-two-inputs-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.board-user-profile-two-inputs-group .big-input {
  margin: 0 10px;
  flex-grow: 2;
}
.board-user-profile-two-inputs-group .small-input {
  flex-grow: 1;
}

.board-user-profile-text-area textarea {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.board-user-profile-submit-form {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.2rem;
}

/* NEW STYLES */

.board-user-profile-input-group.board-user-profile-two-inputs-group
  > div:first-child {
  margin-right: 10px;
}

.board-nav-header-bg{
  background: #0e457c;
}
