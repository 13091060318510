.body {
  background-size: cover;
  font-family: Montserrat;
  width: 100%;
  height: 100%;
}

.logo {
  width: 213px;
  height: 36px;
  margin: 30px auto;
}

.loginBlock {
  width: 320px;
  padding: 20px;
  background: white;
  border-radius: 5px;
  border-top: 5px solid #42A5F5;
  margin: 0 auto;
}

.loginBlock h1 {
  text-align: center;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
}

.loginBlock input {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 14px;
  padding: 0 20px 0 50px;
  outline: none;
}

.loginBlock input#username {
  background: #fff url('http://i.imgur.com/u0XmBmv.png') 20px top no-repeat;
  background-size: 16px 80px;
}

.loginBlock input#username:focus {
  background: #fff url('http://i.imgur.com/u0XmBmv.png') 20px bottom no-repeat;
  background-size: 16px 80px;
}

.loginBlock input#password {
  background: #fff url('http://i.imgur.com/Qf83FTt.png') 20px top no-repeat;
  background-size: 16px 80px;
}

.loginBlock input#password:focus {
  background: #fff url('http://i.imgur.com/Qf83FTt.png') 20px bottom no-repeat;
  background-size: 16px 80px;
}

.loginBlock input:active,
.loginBlock input:focus {
  border: 1px solid #42A5F5;
}

.loginBlock button {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 40px;
  background: #42A5F5;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #42A5F5;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.loginBlock button:hover {
  background: #1E88E5;
}

// input {
//   &.failed {
//     border-color: $palette-red-500;
//   }
// }
button:disabled {
  &.authenticating {
    background-color: #BDBDBD;
    border: 1px solid #BDBDBD;
  }
}

.errorSpan {
  display: block;
  color: #fb5c5c;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
}

.forgotPassword {
  margin-top: 1.5 * 10px;
  b,
  a {
    font-weight: normal;
    font-size: 1.4 * 10px;
  }
}
