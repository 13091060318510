.user-list-container {
  margin-top: 0;
  margin-bottom: 50px;
}
@media screen and (min-width: 576px) {
  .user-list-container {
    margin-top: 30px;
  }
}

.user-list-table {
  margin-bottom: 50px;
}

.user-list-side-menu {
  background-color: white;
  list-style: none;
  border-left: 3px solid #0073e9;
  background-color: white;
  padding: 0;
}
.user-list-side-menu .side-menu-item {
  border-bottom: 1px solid #dbe3e3;
  padding: 21px;
  cursor: pointer;
  font-size: 14px;
}

.user-list-avatar-wrapper {
  display: flex;
  align-items: center;
}

.user-list-avatar-image {
  margin-right: 21px;
}
.user-list-avatar-image div {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.user-list-avatar-image div span {
  line-height: 30px;
}

.user-list-status-indicator {
  align-self: center;
}
.user-list-status-indicator .status-indicator-bullet {
  user-select: none;
  cursor: pointer;
  font-size: 12px !important;
  padding-right: 8px;
}

.user-list-row {
  cursor: pointer;
  padding: 10px 25px 10px 25px !important;
}
.user-list-row.logged-in-user-row {
  background-color: rgba(232, 114, 114, 0.1) !important;
}

.user-list-name-column {
  flex: 1 0 300px !important;
}

.user-list-role-column {
  flex: 0 0 150px !important;
  padding-right: 20px;
}

.user-list-status-column {
  flex: 0 0 100px;
}

.user-list-menu-column {
  flex: 0 0 44px !important;
}

.user-list-dropdown {
  width: 100%;
  padding-bottom: 0px;
  font-size: 11px;
}

.user-list-dropdown .eui-select-field-wrapper > button {
  font-size: 11px;
}

.user-list-customSwitch {
  padding: 0 16px;
  padding-top: 16px;
}
.user-list-customSwitch span {
  white-space: nowrap;
}

.user-list-user-name-wrapper {
  display: flex;
  flex-direction: column;
}
.user-list-user-name-wrapper p {
  line-height: 1;
}
.user-list-user-name-wrapper p .logged-in-user-label {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
}
.user-list-user-name-wrapper .primary-contact-label {
  font-size: 12px;
}

.user-list-confirmation-overlay {
  margin-left: -25px;
  margin-top: -10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
}
.user-list-confirmation-overlay p {
  font-weight: 600;
}
.user-list-confirmation-overlay .user-list-action-icons {
  display: flex;
  align-items: center;
}

.user-list-confirmation-overlay .user-list-action-icons button {
  margin-right: 6px;
}
.user-list-confirmation-overlay .user-list-action-icons button i {
  color: white !important;
  font-weight: 700;
  margin-right: 6px;
}

.user-list-error-overlay {
  margin-left: -25px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
}
.user-list-error-overlay p {
  text-align: center;
  font-weight: 600;
}
.user-list-error-overlay div:first-child,
.user-list-error-overlay div:last-child {
  width: 60px;
}
.user-list-error-overlay .user-list-action-icons {
  display: flex;
  align-items: center;
}
.user-list-error-overlay .user-list-action-icons button {
  color: white !important;
}
.user-list-error-overlay .user-list-action-icons button span {
  font-weight: 700;
}

.row-icon-dropdown {
  display: flex;
  align-items: center;
}
