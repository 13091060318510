.notifications-panel-notification-wrapper {
  border-top: solid black 1px;
  background-color: #f1f5f5;
  color: #aac3c3;
  border: 1px solid #dbe3e3;
  padding: 7px 22px;
  height: 32px;
  font-size: 12px;
}

.notifications-panel-header {
  height: 109px;
  display: flex;
  align-items: center;
}
.notifications-panel-header span {
  font-size: 18px;
}
