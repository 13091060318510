.powered-logo {
	padding-left: 10px;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.flex-center {
	display: flex;
	align-items: center;
}

.flex-end {
	display: flex;
	align-items: flex-end;
}

.eui-navbar-bar a.disable-indicator-bar::after, .eui-navbar-bar button::after {
	background: transparent !important;
}

.format-nav-text {
	letter-spacing: 0px !important;
	font-size: 0.9em !important;
	opacity: 0.8;
}

.eui-navbar-brand.external {
	padding: 17px 15px;
}

.nav-item-icon {
	cursor: pointer;
}

.eui-navbar-bar {
	font-size: 0.9em;
}
