.filters-bar-filters {
  height: 64px;
  border: 1px solid #e6e8ec;
  background-color: white;
  align-items: center;
  display: flex;
  color: #607b7b;
  padding-left: 21px;
  padding-right: 18px;
}

.filters-bar-filtersfilters .filters-bar-searchInput {
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: 400;
}

.filters-bar-filters .filters-bar-searchInput span .eui-icon-button  {
  color: #607b7b !important;
}

.filters-bar-filters .filters-bar-searchInput span span {
  margin: 0;
  font-size: 22px;
}

.filters-bar-filters .filters-bar-searchInput input::placeholder {
  color: #abbdbd !important;
}

.filters-bar-filters .filters-bar-searchInput input {
  font-size: 14px;
  flex: 1;
  border: 0;
  color: #1d2121;
  height: 46px;
  margin-left: 13px;
}
.filters-bar-filters .filters-bar-actions {
  display: flex;
}
.filters-bar-filters .filters-bar-actions button {
  color: #607b7b !important;
  font-size: 22px;
}

.filters-bar-badge {
  position: relative;
  cursor: pointer;
}
.filters-bar-badge div {
  background-color: #42a5f5;
  color: white;
  font-size: 8px;
  font-weight: bold;
  padding: 1px 3px;
  border: solid white 2px;
  position: absolute;
  width: 15px;
  height: 16px;
  display: inline-block;
  text-align: center;
  left: 19px;
  top: 1px;
}
