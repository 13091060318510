.transcript {
  padding-left: 32px;
}

table.transcriptTable {
  border-collapse: collapse;
  width: 100%;
}

table.transcriptTable .header {
  height: 60px;
  text-align: left;
}

table.transcriptTable .header th:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 19px 0 19px 10px;
}

table.transcriptTable .header .subject-area {
  background-color: #fcfdfd;
}

table.transcriptTable .header th:nth-child(n + 2) {
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  width: 83px;
  padding: 12px;
}

table.transcriptTable .header th:nth-child(2) {
  background-color: #0073e9;
}

table.transcriptTable .header th:nth-child(3) {
  background-color: #19c27f;
}

table.transcriptTable .header th:nth-child(4) {
  background-color: #de350b;
}

table.transcriptTable tbody.transcript-body td {
  font-size: 14px;
  line-height: 19px;
}

table.transcriptTable tbody.transcript-body td:nth-child(1) {
  padding-top: 6px;
}

table.transcriptTable tbody.transcript-body td:nth-child(n + 2) {
  text-align: right;
  padding-right: 20px;
}

table.transcriptTable tbody.transcript-body .subjectAreas td {
  border-top: solid #f5f5f5 1px;
}

table.transcriptTable tbody.transcript-body .subjectAreas td:first-child {
  position: relative;
  margin-top: -1px;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 10px;
  font-weight: 600;
}

table.transcriptTable tbody.transcript-body .subjectAreas td:nth-child(n + 2) {
  border-top: solid #f5f5f5 1px;
  /* font-weight: 600; */
}

table.transcriptTable tbody.transcript-body .subjectAreas td:nth-child(2) {
  background-color: rgba(66, 165, 245, 0.1);
}

table.transcriptTable tbody.transcript-body .subjectAreas td:nth-child(3) {
  background-color: rgba(129, 199, 132, 0.1);
}

table.transcriptTable tbody.transcript-body .subjectAreas td:nth-child(4) {
  background-color: rgba(244, 64, 72, 0.1);
}

table.transcriptTable tbody.transcript-body .posting td:first-child {
  padding-top: 0;
  padding-right: 13px;
  padding-left: 30px;
  cursor: pointer;
}

table.transcriptTable tbody.transcript-body .posting td:nth-child(2) {
  background-color: rgba(66, 165, 245, 0.1);
}

table.transcriptTable tbody.transcript-body .posting td:nth-child(3) {
  padding-top: 10px;
  background-color: rgba(129, 199, 132, 0.1);
}

table.transcriptTable tbody.transcript-body .posting td:nth-child(4) {
  background-color: rgba(244, 64, 72, 0.1);
}

table.transcriptTable tbody.transcript-body .posting .courseType {
  font-size: 10px;
}

table.transcriptTable tbody.transcript-body .posting .postName .postingDetailPopover {
  display: grid;
  grid-template-columns: 1fr auto auto;
}

table.transcriptTable tbody.transcript-body .posting .postName .name {
  max-width: 80%;
}

table.transcriptTable tbody.transcript-body .posting .postName .dateEarned {
  cursor: auto;
  font-size: 14px;
  padding-right: 7px;
}

table.transcriptTable tbody.transcript-body .posting .postName .postingMoreOptions {
  opacity: 0;
}

table.transcriptTable tbody.transcript-body .posting .postName:hover .postingMoreOptions {
  opacity: 1;
}

table.transcriptTable tbody.transcript-body .posting .postName .messages {
  cursor: auto;
  width: 100%;
  float: left;
  font-size: 10px;
  color: #de350b;
}

table.transcriptTable tbody.transcript-body .lastPosting>td {
  padding-bottom: 18px;
}

table.transcriptTable tbody.transcript-body .subjectChildren td:first-child {
  padding-top: 10px;
  padding-left: 18px;
  cursor: auto;
}

table.transcriptTable tbody.transcript-body .total td:first-child {
  border-top: solid #f5f5f5 1px;
  position: relative;
  padding-right: 33px;
  font-weight: 600;
  text-align: right;
}

table.transcriptTable tbody.transcript-body .total td:nth-child(n + 2) {
  color: white;
  font-weight: 600;
  line-height: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
}

table.transcriptTable tbody.transcript-body .total td:nth-child(2) {
  background-color: #0073e9;
}

table.transcriptTable tbody.transcript-body .total td:nth-child(3) {
  background-color: #19c27f;
}

table.transcriptTable tbody.transcript-body .total td:nth-child(4) {
  background-color: #de350b;
}

table.transcriptTable tbody.transcript-body .subjectAreas:first-child td {
  padding-top: 6px;
}

.transcript-popover {
  max-width: 427px !important;
  width: 427px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding: 0 !important;
}

.transcript-popover .popover-content {
  padding: 0 !important;
}

.popoverOverlayBody {
  padding: 11px 20px 15px 34px;
  max-height: 500px;
  overflow-y: auto;
}

.popoverOverlayBody .courseInfoSection {
  font-size: 14px;
}

.popoverOverlayBody table.courseInfo tr:first-child td {
  padding-top: 0 !important;
}

.popoverOverlayBody table.courseInfo td:first-child {
  padding-top: 10px !important;
  padding-left: 0 !important;
  padding-right: 20px;
  white-space: nowrap;
}

.popoverOverlayBody table.courseInfo td:nth-child(2) {
  background-color: transparent !important;
  padding: 10px 0 0 10px !important;
  text-align: left !important;
  color: #1d2121;
}

.popoverOverlayBody table.subjectAreas {
  width: 100%;
  font-size: 14px;
}

.popoverOverlayBody table.subjectAreas thead {
  border: none;
}

.popoverOverlayBody table.subjectAreas tr:first-child th:first-child {
  padding-top: 0 !important;
  text-align: left;
}

.popoverOverlayBody table.subjectAreas tr:first-child th:nth-child(2) {
  padding-top: 0 !important;
  text-align: right;
}

.popoverOverlayBody table.subjectAreas td {
  border: none !important;
}

.popoverOverlayBody table.subjectAreas td:first-child {
  padding-top: 10px !important;
  padding-left: 0 !important;
  color: #607b7b !important;
}

.popoverOverlayBody table.subjectAreas td:nth-child(2) {
  background-color: transparent !important;
  padding: 10px 0 0 10px !important;
  text-align: right !important;
}

.popoverOverlayBody div.popoverOverlayBodySeparator {
  margin: 20px 0;
  border-bottom: 1px solid #DBE3E3;
}

.popoverOverlayBody h6 {
  padding-bottom: 10px;
  font-size: 18px;
}

.transcript-hidden {
  display: none;
}

.transcript-question {
  margin-bottom: 23px;
  font-size: 14px
}

.transcript-question p {
  margin-bottom: 6px;
}

.transcript-attachmentBox {
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
}

.transcript-attachmentBox .attachmentImg {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.flag-transcript-table {
  margin-top: 30px;
}

.posting-head {
  padding: 5px 10px 14px 35px;
  border-bottom: 1px solid #F1F5F5;
}

.posting-head h6 {
  font-size: 18px;
}





.post-type {
  font-size: 12px;
  opacity: 1;
}

.transcript-loader-container {
  display: flex;
  justify-content: center;
}

.edit-hours-input {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: baseline;
}

.edit-hours-course-title {
  margin-bottom: 30px;
}

#edit-hours-modal .modal-body {
  padding: 30px 35px;
}

.edit-hours-input-separator {
  padding: 0 20px;
  font-size: 18px;
  font-weight: bold;
}

.edit-hours-input-label {
  font-weight: bold;
}

.edit-hours-sa {
  font-size: 14px;
  padding: 3% 0;
}

.edit-hours-comment-box {
  margin-top: 30px;
}

.edit-hours-comment-box-title {
  color: gray;
  font-size: 12px;
  margin-bottom: 10px;
}

.transcript-edited-hours-msg {
  color: gray;
  font-size: 11px;
  text-decoration: none;
  border-bottom: dotted 1px gray;
}

.transcript-edited-hours-msg:hover {
  color: gray;
  text-decoration: none;
}

.transcript-edited-hours-msg:visited {
  color: gray;
  text-decoration: none;
}

.transcript-edited-hours-msg:active {
  color: gray;
  text-decoration: none;
}

.disallowed-popover-main {
  padding: 30px;
}

.disallowed-popover-date {
  color: gray;
  font-size: 12px;
}

.disallowed-popover-desc {
  margin-bottom: 30px;
}

.disallowed-popover-comment {
  display: flex;
  margin-top: 25px;
}

.disallowed-popover-commnet-text {
  margin-left: 10px;
}

.disallowed-popover-edited {
  width: 100%;
}

.transcript-popover-trigger.eui-overlay-trigger {
  justify-content: left;
}