.table-v2-table {
  font-size: 12px;
  font-weight: 400;
  color: #455a64;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  display: flex;
  flex-direction: column;
  position: relative;
}

.table-v2-row {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 19px 25px;
  border-bottom: 1px solid #e6e8ec;
}

.table-v2-header {
  display: flex;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  color: #607b7b;
  user-select: none;
}

.table-v2-header .table-v2-col {
  align-items: center;
}

.table-v2-header .table-v2-col.table-v2-sorteable {
  cursor: pointer;
}

.table-v2-header .table-v2-col.table-v2-sorted {
  color: #424242;
}

.table-v2-body {
  position: relative;
}

.table-v2-body .table-v2-row {
  font-size: 13px;
}

.table-v2-body .table-v2-row:nth-child(odd) {
  background-color: rgba(225, 245, 254, 0.1);
}

.table-v2-body .table-v2-row:hover {
  background-color: #fafafa;
}

.table-v2-body .table-v2-row.table-v2-selected {
  background-color: #f5f5f5;
}

.table-v2-col {
  display: flex;
  flex: 1;
  padding: 0 4px;
}

.table-v2-arrow {
  align-self: center;
  font-size: 13px;
  margin-left: 5px;
}

.table-v2-loader-overlay {
  text-align: center;
  display: flex;
}
.table-v2-loader-overlay .table-v2-loader-text {
  margin-top: 10px;
  font-size: 30px;
}

.table-v2-blank-state {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: white;
}
.table-v2-blank-state .table-v2-blank-state-text {
  user-select: none;
  font-size: 70px;
  margin: 0 auto;
  color: rgba(117, 117, 117, 0.2);
  text-shadow: 1px 1px white, -1px -1px rgba(117, 117, 117, 0.2);
}
